import React from 'react';
import { Button, Container, Col, Row, Card, CardGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam } from '@fortawesome/free-brands-svg-icons';

import { SocialContact } from '../components/SocialContact';

import ac_gameplay from '../assets/images/games/Arcade Classics/Gameplay-demo.gif';
import nt2_concept from '../assets/images/games/NinjaThea 2/NinjaThea-2-demo.gif';

export const Home = () => {
    return (
        <>
            <div className='hero-container'>
                <Container>
                    <Row>
                        <Col className='hero-container-column' md>
                            From pixels to play:
                            <br /><span className='hero-container-header'>Indie games for everyone!</span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='mt-5 wrapper'>
                <Row className='justify-content-md-center'>
                    <CardGroup className='card-group-centered'>
                        <Card className='m-2' style={{ width: '18rem' }}>
                            <Card.Img variant='top' src={nt2_concept} />
                            <Card.Body>
                                <Card.Title>NinjaThea 2 (Coming 2024)</Card.Title>
                                <Card.Text>
                                    Sequel to <a className='cstm-link' href='https://store.steampowered.com/app/2005800/NinjaThea/' target='_blank' rel='noopener noreferrer'>NinjaThea
                                    </a>, a casual ninja girl platformer. The critters are back for revenge!
                                </Card.Text>
                                <Button variant='dark' href='https://store.steampowered.com/app/2567920/NinjaThea_2/' target='_blank'>
                                    <FontAwesomeIcon icon={faSteam} style={{ marginRight: 5 }} />
                                    Visit on Steam
                                </Button>
                            </Card.Body>
                        </Card>
                        <Card className='m-2' style={{ width: '18rem' }}>
                            <Card.Img variant='top' src={ac_gameplay} />
                            <Card.Body>
                                <Card.Title>Arcade Classics</Card.Title>
                                <Card.Text>
                                    Collection of arcade classics, remade with a fresh and friendly art style and catchy soundtrack.
                                </Card.Text>
                                <Button variant='dark' href='https://store.steampowered.com/app/2170700/Arcade_Classics/' target='_blank'>
                                    <FontAwesomeIcon icon={faSteam} style={{ marginRight: 5 }} />
                                    Visit on Steam
                                </Button>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Row>
                {/* <Separator /> */}
                <Row>
                    <Col className='mt-3 mb-2' md>
                        <Container className='mt-4 center'>
                            <Button className='m-2' as={Link} to='/games' variant='dark'>
                                Games
                            </Button>
                            <Button className='m-2' as={Link} to='/contact' variant='dark'>
                                Contact
                            </Button>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <div className='social-contact-container'>
                <SocialContact />
            </div>
        </>
    );
}
